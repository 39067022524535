import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import 'semantic-ui-css/semantic.min.css'

export default createGlobalStyle`
  ${styledNormalize}

  @font-face {
    font-family: "Avenir";
    font-weight: 400;
    font-style: normal;
    src: url("/avenir-400.woff2") format("woff2");
    font-display: swap;
  }

  body {
    font-family: "Avenir", Tahoma, Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    line-height: 1.65;
    color: #373F49;
    background: #eee;
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  h1,
  h2,
  h3 {
    font-size: 2em;
    font-weight: normal;
  }

  h2 {
    line-height: 1.2;
    font-weight: 700;
  }

  h4 {
    line-height: 1.1em;
  }
`
