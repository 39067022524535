import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, url, metadata: { text, keywords }, pathname }) =>
  <Helmet
    htmlAttributes={{ lang: 'it' }}
    title={title}
    meta={[
      { name: 'description', content: text.text },
    ]}
  >
    <link rel="icon" type="image/svg+xml" href="/logo/vector/isolated-layout.svg" sizes="any" />
    <meta name="description" content={text.text} />
    <meta name="keywords" content={keywords.join(", ")} />

    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={text.text} />
    <meta name="twitter:card" content="summary" />

    <meta property="og:title" content={title} />
    <meta property="og:description" content={text.text} />
    <meta property="og:url" content={url} />
  </Helmet>

export default SEO;
