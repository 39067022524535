import React from 'react'
import { Icon } from 'semantic-ui-react'
import Logo from '~/components/Logo'

import styled from 'styled-components'

const BackgroundWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgray};
  padding: 20px;
  padding-bottom: 14px;
`

const Container = styled.ul`
  list-style: none;
  padding: 0 14px;
  margin: 0;
`

const Item = styled.li`
`

const Email = ({ email }) => (
  <a href={`mailto:${email}`}><Icon name="mail" />{email}</a>
)

const Phone = ({ phone }) => (
  <a href={`tel:${phone}`}><Icon name="phone square" />{phone}</a>
)

const Cell = ({ cell }) => (
  <a href={`tel:${cell}`}><Icon name="mobile alternate" />{cell}</a>
)

const Fax = ({ fax }) => (
  <a href={`fax:${fax}`}><Icon name="fax" />{fax}</a>
)

const Address = ({ address }) => (
  <a href={`https://www.google.it/maps/place/${address}`} target="_blank"><Icon name="location arrow" />{address}</a>
)

const IVA = ({ iva, cf }) => (
  <p>P.I. {iva} | C.F. {cf}</p>
)

export default ({ data: {
  name,
  email,
  phone,
  cell,
  fax,
  address,
  iva,
  cf,
}}) => (
  <BackgroundWrapper>
    <Logo />
    <Container>
      <Item><b>{name}</b></Item>
      {email && <Item><Email email={email} /></Item>}
      {phone && <Item><Phone phone={phone} /></Item>}
      {cell && <Item><Cell cell={cell} /></Item>}
      {fax && <Item><Fax fax={fax} /></Item>}
      {address && <Item><Address address={address} /></Item>}
      {iva  && cf && <Item><IVA iva={iva} cf={cf} /></Item>}
      <br />
      <a href="/privacy" target="__blank">Informativa sulla Privacy e Cookie</a>
    </Container>
  </BackgroundWrapper>
)
