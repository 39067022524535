import React from 'react'
import { Link as InternalLink } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import { Dropdown, Menu } from 'semantic-ui-react'
import Logo from '~/components/Logo'

import styled from 'styled-components'

import config from '~/config.json'

const Navigation = styled.nav`
  border: 0;
  background: #333;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px -5px #222;
  z-index:  2;
  max-width: ${({ theme }) => theme.maxWidth};
  position: ${props => props.sticky ? 'fixed' : 'relative'};
  animation: ${props => props.sticky ? 'moveDown 0.5s ease-in-out' : 'none'};


  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }

  .active {
    border-bottom: 5px solid ${({ theme }) => theme.colors.blue};
  }

  .item {
    height: 100%;
  }

  @media (max-width: 600px) {
    overflow: hidden;
  }
`

const NavMenu = styled(Menu)`
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
`

const LogoWrapper = styled(Menu.Item)`
  @media (max-width: 600px) {
    margin-right: 70px;
  }
`

const LINK_OFFSET = -110

export default ({ location, pages, sticky }) => (
  <Navigation role="navigation" sticky={sticky}>
    <NavMenu borderless>
      <InternalLink
        to="/"
        activeClass="active"
        spy={true}
        hashSpy={true}
        smooth={true}
        offset={LINK_OFFSET}
        duration= {500}
      >
        <LogoWrapper link position="right">
          <Logo />
        </LogoWrapper>
      </InternalLink>
      <NavMenu floated="right" pointing borderless>
        {pages.map(({ slug, title, services }) => {
          if (title === 'Home') return
          let Link = ScrollLink
          if (location.pathname !== '/') {
            Link = InternalLink
            slug = `/#${slug}`
          }

          if (services) {
            return (
              <Dropdown item simple text={<Link
                to={`#${slug}`}
                activeClass="active"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={LINK_OFFSET}
                duration= {500}
              >{title}</Link>}>
                  <Dropdown.Menu>
                    {services.map(service =>
                      <InternalLink to={`/${config.service.url}/${service.slug}`}>
                        <Dropdown.Item>
                            {service.title}
                        </Dropdown.Item>
                      </InternalLink>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
            )
          }

          return (
            <Link
              to={slug}
              activeClass="active"
              spy={true}
              hashSpy={true}
              smooth={true}
              offset={LINK_OFFSET}
              duration= {500}
            >
              <Menu.Item link>
                  {title}
              </Menu.Item>
            </Link>
          )
        })}
      </NavMenu>
    </NavMenu>
  </Navigation>
)
