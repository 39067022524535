import React, { useEffect, useState, useRef } from 'react'
import Helmet from 'react-helmet'

import { ThemeProvider } from 'styled-components'


import Modal from '~/components/Modal'
import Navigation from '~/components/Navigation'
import BackgroundWrapper from '~/components/BackgroundWrapper'
import Footer from '~/components/Footer'


import theme from '~/styling/theme'
import GlobalStyle from '~/styling/GlobalStyle'

import Container from '~/components/Container'
import SEO from '~/components/SEO'

export default ({ title, url, message, pages, metadata, location, children }) => {
  // sticky
  if (typeof window !== `undefined`) {
    useEffect(() => {
      return () => {
        window.removeEventListener("scroll", () => handleScroll)
      }
    }, [])
  }

  const [isSticky, setSticky] = useState(false)

  const stickyRef = useRef(null)
  const handleScroll = () => {
    if (stickyRef.current) {
      setSticky(stickyRef.current.getBoundingClientRect().top < 0);
    }
  };

  if (typeof window !== `undefined`) {
    window.addEventListener("scroll", handleScroll)
  }

  let rootPath = `/`
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`
  }

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  // prevents ssr flash to fix modal
  if (!mounted) return null

  return (
    <ThemeProvider theme={theme}>
      <SEO title={title} url={url.concat(location.pathname)} metadata={metadata} />
      <Helmet title={title} />
      <GlobalStyle />
      {message && message.node.active && <Modal message={message.node} />}
      <Container>
        <Navigation pages={pages} sticky={isSticky} location={location} />
        <BackgroundWrapper ref={stickyRef}>
          {children}
          <Footer data={metadata} />
        </BackgroundWrapper>
      </Container>
    </ThemeProvider>
  )
}
