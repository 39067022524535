import React from 'react'

import styled from 'styled-components'


const Logo = styled.img`
  width: 300px !important;
`

export default ({ location, pages, sticky }) => (
  <Logo src="/logo/logo.png" />
)
