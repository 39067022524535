import React, { useState } from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export default ({message}) => {
  const [isOpen, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeIcon
    >
      <Modal.Header>{message.title}</Modal.Header>
      <Modal.Content>
        {documentToReactComponents(message.content.json)}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>
          <Icon name='close' /> Chiudi
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
