const colors = {
  white: '#fefefe',
  black: '#19191A',
  superdarkgray: '#444',
  darkgray: '#6f6f6f',
  midgray: '#D9D9D9',
  lightgray: '#f4f4f4',
  blue: '#3355AA',
  primary: '#4A126B',
  secondary: '#FDFC5D'
};

export default {
  fontWeights: {
    regular: 500,
    medium: 600,
    bold: 700,
    black: 900
  },
  breakpoints: [
    '420px',
    '768px',
    '1024px'
  ],
  fontSizes: [
    12, 14, 16, 20, 24, 28, 32, 48, 64
  ],
  maxWidth: '1180px',
  colors,
  space: [
    0, 5, 10, 15, 20, 25, 30, 35, 40
  ],
  fonts: {
    sans: 'ibm-plex-sans, system-ui, sans-serif',
    serif: 'freight-text-pro, serif',
    mono: 'ibm-plex-mono, monospace',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    primary: '4px 4px 0px 0px #878787'
  },
  buttons: {
    primary: {
      background: colors.primary,
      border: `1px solid ${colors.primary}`,
      color: colors.white,
      fontWeight: 700
    },
    outline: {
      background: 'transparent',
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      ':hover': {
        background: colors.lightgray,
        color: colors.primary,
      },
      fontWeight: 700
    },
    outlineWhite: {
      background: 'transparent',
      border: `1px solid ${colors.white}`,
      color: colors.white,
      ':hover': {
        background: colors.white,
        color: colors.primary,
      }
    }
  }
}
